<template>
  <div class="navbar">
    <div class="name-text">
      Cody Kreppein
    </div>
    <div class="nav-links">
      <router-link to="/">Home</router-link>
      <router-link to="/Videos">Videos</router-link>
      <router-link to="/SocialMedia">Social Media</router-link>
      <router-link to="/Contact">Contact</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar'
};
</script>

<style lang="scss" scoped>
.name-text {
  color: #d70403;
  font-size: 40px;
  padding-top: 0.75em;
}

.nav-links {
  min-height: 4em;
  line-height: 4em;
}

a {
  display: inline-block;
  padding-right: 1em;
  padding-left: 1em;
  text-decoration: none;
  color: white;
  font-size: 20px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  &:hover {
    filter: brightness(50%);
  }
  &.router-link-active {
    color: orange;
  }
}
</style>
