<template>
  <div class="social-media-link">
    <a :href="url">
      <img :src="require(`../../assets/img/${image}`)" />
      <span class="description">{{ text }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaLink',
  props: {
    image: String,
    url: String,
    text: String
  }
};
</script>

<style lang="scss" scoped>
.social-media-link {
  text-align: left;
  margin-top: 1em;
}

img {
  vertical-align: middle;
  height: 4em;
}

a {
  text-decoration: none;
  color: #e6e8e9;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  &:hover {
    filter: brightness(50%);
  }
}

.description {
  margin-left: 1em;
  font-size: 18px;
}
</style>