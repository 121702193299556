<template>
  <div class="contact">
    For business inquiries, please contact Cody via email at
    <a href="mailto:SongTelephoneMusic@gmail.com">SongTelephoneMusic@gmail.com</a>.
    <br /><br />
    If you would like to send Cody a tip for his work, you can do so through the following methods:
    <br /><br />
    <div class="payment-options">
      <div class="option">
        <img src="../../assets/img/cashapp.png" />
        <span class="description">$CodyKreppein on Cashapp</span>
      </div>
      <div class="option">
        <img src="../../assets/img/paypal.png" />
        <span class="description">CAKrep@aol.com on PayPal</span>
      </div>
      <div class="option">
        <img class="qr-code" src="../../assets/img/paypalqrcode.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView'
};
</script>

<style lang="scss" scoped>
.contact {
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #e6e8e9;
}

.payment-options {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.option {
  margin-top: 1em;
  > img {
    vertical-align: middle;
    height: 4em;
    &.qr-code {
      height: 15em;
    }
  }
}

.description {
  margin-left: 1em;
}
</style>
