<template>
  <div class="social-media">
    You can find Cody on various social media platforms:
    <div class="links">
      <SocialMediaLink image="facebook.png" url="https://www.facebook.com/SongTelephone" text="Song Telephone on Facebook" />
      <SocialMediaLink image="youtube.png" url="https://www.youtube.com/channel/UCorfA-lMkZW8pehWArez1aQ" text="Song Telephone on YouTube" />
      <SocialMediaLink image="twitter.png" url="https://twitter.com/SongTelephone" text="@SongTelephone on Twitter" />
      <SocialMediaLink image="tiktok.png" url="https://www.tiktok.com/@songtelephone" text="@songtelephone on Tiktok" />
    </div>
  </div>
</template>

<script>
import SocialMediaLink from '@/components/SocialMediaLink.vue';

export default {
  name: 'SocialMediaView',
  components: {
    SocialMediaLink
  }
};
</script>

<style scoped>
.social-media {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

.links {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}
</style>
