import { createWebHashHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Videos from "@/views/Videos.vue";
import SocialMedia from "@/views/SocialMedia.vue";
import Contact from "@/views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/Videos",
    name: "Videos",
    component: Videos
  },
  {
    path: "/SocialMedia",
    name: "SocialMedia",
    component: SocialMedia
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;