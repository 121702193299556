<template>
  <div class="home">
    <img src="../../assets/img/cody.png" />
    <p>
      Cody Kreppein is a Savannah-based musician, songwriter, and voice actor with a knack for parodies, covers,
      and unique musical transformations.
      <br /><br />
      Some examples of Cody's creative endeavors include performing Bohemian Rhapsody in the style of Johnny Cash,
      Good Riddance (Time of Your Life) as an Acoustic Guitar/Kazoo cover, and rapping from A to Z in the style
      of 26 different cartoon characters. If any of that sounds interesting to you, check these out in the
      Videos or Social Media sections!
      <br /><br />
      Cody also regularly performs live entertainment; if you'd like to book Cody for any services, please navigate
      to the Contact section to get in touch.
    </p>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
};
</script>

<style lang="scss" scoped>
.home {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

img {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

p {
  text-align: left;
  padding-left: 1em;
  display: inline-block;
  width: 45%;
  @media (max-width: 600px) {
    width: 95%;
  }
}
</style>
