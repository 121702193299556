<template>
  <div class="videos">
    Here are the three latest uploads from the Song Telephone YouTube channel:
    <div v-for="(video, index) in videos" :key="index">
      <br />
      <iframe id="ytplayer" type="text/html" width="400" height="240"
        :src="'https://www.youtube.com/embed/' + video.id.videoId + '?autoplay=0'"
        frameborder="0">
      </iframe>
    </div>
  </div>
</template>

<script>
const key = 'AIzaSyDv64HChcwinRXD237z7wcQYyYjiVb1az4';
const getVideosUrl = 'https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCorfA-lMkZW8pehWArez1aQ&maxResults=3&order=date&type=video&key=';

export default {
  name: 'VideosView',
  data() {
    return {
      videos: []
    };
  },
  mounted() {
    var self = this;
    self.axios.get(getVideosUrl + key).then(response => self.videos = response.data.items);
  }
};
</script>

<style scoped>
#ytplayer {
  max-width: 640px;
}
</style>
